// al final de tot hi ha un export
function llista_modalitats() {
	var modalitats = [
	{
		"modalitat":"BTT",
		"value":"btt",
		"id":"chk_btt"
	},
	{
		"modalitat":"senderisme",
		"value":"senderisme",
		"id":"chk_senderisme"
	},
	{
		"modalitat":"ciclisme",
		"value":"ciclisme",
		"id":"chk_ciclisme"
	}
	]

	return modalitats;
}
export default llista_modalitats;
